// 设置 cookie
export function setCookie(name, value, days = 30) {
    const exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(value)}; path=/; domain=tiktokvoice.net; expires=${exp.toUTCString()}`;
}

// 获取 cookie
export function getCookie(name) {
    const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
    const arr = document.cookie.match(reg);
    if (arr) {
        return decodeURIComponent(arr[2]);
    }
    return null;
}

// 删除 cookie
export function delCookie(name) {
    document.cookie = `${name}=; path=/; domain=tiktokvoice.net; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}

// 检查 cookie 是否存在
export function checkCookie(name) {
    return getCookie(name) !== null;
}

// 获取所有 cookie
export function getAllCookies() {
    const cookies = {};
    document.cookie.split(';').forEach(cookie => {
        const [name, value] = cookie.split('=').map(c => c.trim());
        if (name) cookies[name] = decodeURIComponent(value);
    });
    return cookies;
}

// 设置带有其他选项的 cookie
export function setAdvancedCookie(name, value, options = {}) {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (options.expires) {
        if (typeof options.expires === 'number') {
            const d = new Date();
            d.setTime(d.getTime() + options.expires * 24 * 60 * 60 * 1000);
            options.expires = d;
        }
        cookieString += `; expires=${options.expires.toUTCString()}`;
    }

    if (options.path) cookieString += `; path=${options.path}`;
    if (options.domain) cookieString += `; domain=${options.domain}`;
    if (options.secure) cookieString += '; secure';
    if (options.sameSite) cookieString += `; samesite=${options.sameSite}`;

    document.cookie = cookieString;
}
